import { conditionalDisplay } from '@javaabu/js-utilities';

conditionalDisplay.init();

import {
    format,
    addDays,
    parseISO,
    parse,
    getYear,
    getMonth,
    getDate,
    isSameDay,
} from "date-fns";
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

/**
 * We'll register a HTTP interceptor to attach the "CSRF" header to each of
 * the outgoing requests issued by this application. The CSRF middleware
 * included with Laravel will automatically verify the header's value.
 */
(function () {
    var token = document.head.querySelector('meta[name="csrf-token"]');
    var site_domain = window.location.origin;

    if (token) {
        var op = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function (method, url) {
            var resp = op.apply(this, arguments);
            var internal_request =
                url.indexOf(site_domain, 0) === 0 || url.indexOf("http") !== 0;

            if (internal_request) {
                this.setRequestHeader("X-CSRF-Token", token.content);
            }

            return resp;
        };
    }
})();

window.dateFns = {
    format,
    addDays,
    parseISO,
    getYear,
    getMonth,
    getDate,
    isSameDay,
    parse,
};

window.redirectPage = function (redirect_url) {
    if (redirect_url) {
        if (redirect_url.indexOf("#") == 0) {
            window.location.hash = redirect_url;
            window.location.reload();
        } else {
            window.location.replace(redirect_url);
        }
    } else {
        window.location.reload();
    }
};

/**
 * Toggle preloader
 */
window.togglePreloader = function (show) {
    if (show) {
        $(".page-loader").show();
    } else {
        $(".page-loader").hide();
    }
};


/**
 * Show loading
 */
window.toggleLoading = function (elem, show) {
    if (elem.hasClass('zmdi')) {
        if (show) {
            elem.addClass('loading');
        } else {
            elem.removeClass('loading');
        }
    } else {
        if (show) {
            var loading = '<i class="zmdi zmdi-spinner zmdi-spin zmdi-pulse loading"> ';
            elem.children('i').hide();
            elem.prepend(loading);
        } else {
            elem.children('.loading').remove();
            elem.children('i').show();
        }
    }

    togglePreloader(show);
};

/**
 * Notification alert
 * @param title
 * @param message
 * @param type
 */
window.notify = function (title, message, type) {
    $.notify({
        message: message,
        title: title
    }, {
        placement: {
            from: 'bottom',
            align: 'right'
        },
        type: type,
        allow_dismiss: true,
        animate: {
            enter: 'animate__animated animate__fadeInUp',
            exit: 'animate__animated animate__fadeOutDown'
        }
    });
};

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(document).scrollTop() > 20) {
            $("#navbar").addClass("pt-0 expanded");
            $("#expand-scroll").removeClass("mx-2");
        } else {
            $("#expand-scroll").addClass("mx-2");
            $("#navbar").removeClass("pt-0 expanded");
        }
    });

    /**
     * Auto submit filters
     */
    $('.auto-submit input[type=number], .auto-submit input[type=text], .auto-submit input[type=checkbox], .auto-submit input[type=radio], .auto-submit select').on('change', function (e) {
        var form = $(this).closest('form');
        if (form.length) {
            togglePreloader(true);
            form.submit();
        }
    });

    // dropzone
    $("form[data-ajax-submit]").on("submit", function (e) {
        // https://jquery.malsup.com/form/#api
        var _this = this;

        // submit the form
        $(this).ajaxSubmit({
            beforeSubmit: function (arr, form, options) {
                togglePreloader(true);
            },

            error: function (xhr) {
                togglePreloader(false);

                if (xhr.status == 422) {
                    showValidationErrorMsg(xhr, $(_this));
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "An error occurred while saving.",
                        icon: "error",
                    });
                }
            },

            success: function (response, statusText, xhr, form) {
                var edit_url = response.edit_url;
                var upload_url = response.upload_url;
                var dropzone_elems = form.find(
                    '[data-dropzone-delay-submit="true"]'
                );
                var total_dropzones = dropzone_elems.length;
                var processed_dropzones = 0;

                if (total_dropzones > 0) {
                    dropzone_elems.each(function () {
                        var dropzone_obj = Dropzone.forElement(this);

                        dropzone_obj.options.url = upload_url;
                        dropzone_obj.on("queuecomplete", function () {
                            processed_dropzones++;

                            if (processed_dropzones >= total_dropzones) {
                                redirectPage(edit_url);
                            }
                        });

                        if (dropzone_obj.getQueuedFiles().length > 0) {
                            dropzone_obj.processQueue();
                        } else {
                            processed_dropzones++;

                            if (processed_dropzones >= total_dropzones) {
                                redirectPage(edit_url);
                            }
                        }
                    });
                } else {
                    redirectPage(edit_url);
                }
            },
        });

        // return false to prevent normal browser submit and page navigation
        return false;
    });

    $("[data-dropzone-url]").each(function () {
        var dropzone_url = $(this).data("dropzone-url");
        var data = $(this).data("dropzone-data") || {};
        var param_name = $(this).data("dropzone-param") || "file";
        var delete_url = $(this).data("dropzone-delete-url");
        var files = $(this).data("dropzone-files") || {};
        var max_files = $(this).data("dropzone-max-files") || null;
        var max_size = $(this).data("dropzone-max-size") || 2;
        var accepted =
            $(this).data("dropzone-accept") || "image/png, image/jpeg";
        var auto_process = !($(this).data("dropzone-delay-submit") || false);
        var parent_el = this;

        var dropzone_el = new Dropzone(this, {
            url: dropzone_url,
            paramName: param_name,
            autoProcessQueue: auto_process,
            dictResponseError: "Error uploading file!",
            dictDefaultMessage: "Drop files here to upload",
            dictFileTooBig:
                "File is too big ({{filesize}} MB). Max filesize: {{maxFilesize}} MB",
            dictInvalidFileType: "You can't upload files of this type.",
            maxFilesize: max_size,
            maxFiles: max_files,
            uploadMultiple: false,
            parallelUploads: 50,
            acceptedFiles: accepted,
            error: function (file, response) {
                var message = response;

                if (typeof message !== "string") {
                    message = message.error
                        ? message.error
                        : "Error uploading file!";
                }

                file.previewElement.classList.add("dz-error");
                var _ref = file.previewElement.querySelectorAll(
                    "[data-dz-errormessage]"
                );
                var _results = [];
                var _len = _ref.length;

                for (var _i = 0; _i < _len; _i++) {
                    var node = _ref[_i];
                    _results.push((node.textContent = message));
                }

                return _results;
            },
            init: function () {
                if (data) {
                    this.on("sending", function (file, xhr, formData) {
                        for (const key in data) {
                            if (data.hasOwnProperty(key)) {
                                var val = data[key];
                                var form_val = val;

                                // find dynamic value
                                if (
                                    typeof val === "object" &&
                                    val.hasOwnProperty("el")
                                ) {
                                    form_val = $(val.el).val();

                                    if (!form_val) {
                                        form_val = val.default;
                                    }
                                }

                                formData.append(key, form_val);
                            }
                        }
                    });
                }

                this.on("maxfilesexceeded", function (file) {
                    this.removeFile(file);
                    Swal.fire({
                        title: "Warning!",
                        text: "Maximum number of allowed files exceeded.",
                        confirmButtonText: "Ok",
                        icon: "warning",
                    });
                });

                this.on("success", function (file, response) {
                    file.id = response.id;
                    file.delete_url = response.delete_url;

                    if (
                        response.thumb &&
                        file.previewElement &&
                        file.previewElement.classList.contains(
                            "dz-file-preview"
                        )
                    ) {
                        if (response.type === "video") {
                            file.previewElement.classList.add(
                                "dz-image-preview"
                            );
                        }

                        file.thumb = response.thumb;
                        this.emit("thumbnail", file, file.thumb);
                    }
                });

                this.on("addedfile", function (file) {
                    // Create the remove button
                    var removeButton = Dropzone.createElement(
                        '<a class="dz-remove">Remove file</a>'
                    );

                    if (file.type === "video") {
                        file.previewElement.classList.add("dz-image-preview");
                    }

                    // Capture the Dropzone instance as closure.
                    var _this = this;
                    // Listen to the click event
                    removeButton.addEventListener("click", function (e) {
                        // Make sure the button click doesn't submit the form:
                        e.preventDefault();
                        e.stopPropagation();

                        // Remove the file preview.
                        if (typeof file.id !== "undefined") {
                            Swal.fire({
                                title: "Are you sure you want to remove this file?",
                                text: "You will not be able to undo this delete operation!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, Delete!",
                                cancelButtonText: "Cancel",
                            }).then(function (result) {
                                if (result.value) {
                                    //remove file extension
                                    var id = file.id;

                                    $.ajax({
                                        type: "DELETE",
                                        url: delete_url || file.delete_url,
                                        contentType: "application/json",
                                        beforeSend: function () {
                                            toggleLoading(
                                                $(removeButton),
                                                true
                                            );
                                        },
                                        complete: function () {
                                            toggleLoading(
                                                $(removeButton),
                                                false
                                            );
                                        },
                                        success: function (data) {
                                            _this.removeFile(file);
                                            notify(
                                                "Success!",
                                                "File" +
                                                file.name +
                                                " removed successfully.",
                                                "success"
                                            );
                                        },
                                        error: function (
                                            jqXHR,
                                            textStatus,
                                            errorThrow
                                        ) {
                                            if (jqXHR.status != 404) {
                                                notify(
                                                    "Error!",
                                                    "Error while removing file" +
                                                    file.name,
                                                    "danger"
                                                );
                                            } else {
                                                _this.removeFile(file);
                                            }
                                        },
                                    });
                                }
                            });
                        } else {
                            _this.removeFile(file);
                        }
                    });

                    // Add the button to the file preview element.
                    file.previewElement.appendChild(removeButton);
                });

                //add files to dropzone
                for (var i in files) {
                    var file = files[i];
                    file.accepted = true;

                    this.files.push(file);
                    this.emit("addedfile", file);
                    this.emit("thumbnail", file, file.thumb);
                    this.emit("complete", file);
                }
            },
        });
    });

    // Enable the section only when another element is a
    // given value
    $('[data-enable-section-value]').each(function () {
        var _this = $(this);
        var value = _this.data('enable-section-value');
        var element = $(_this.data('enable-elem'));
        var hide_fields = _this.data('hide-fields') || false;
        var disable_on_select = _this.data('disable') || false;

        if (element.length) {
            element.on('change', function () {
                var curr_val = $(this).val();
                var matching_selected = false;

                if (value instanceof Array) {
                    matching_selected = value.findIndex(function (e) {
                        return e == curr_val;
                    }) > -1;
                } else {
                    matching_selected = curr_val == value;
                }

                var enable = disable_on_select ? (!matching_selected) : matching_selected;

                if (enable) {
                    // if enable, enable only auto-disabled
                    _this.find('select.auto-disabled:disabled, input.auto-disabled:disabled, textarea.auto-disabled:disabled').each(function () {
                        $(this).prop('disabled', false)
                            .removeClass('auto-disabled');
                    });
                } else {
                    // if disable, enable only enabled
                    _this.find('select:enabled, input:enabled, textarea:enabled').each(function () {
                        $(this).prop('disabled', true)
                            .addClass('auto-disabled');
                    });
                }

                if (hide_fields) {
                    if (enable) {
                        _this.slideDown();
                    } else {
                        _this.slideUp();
                    }
                }
            }).trigger('change');
        }
    });

    $("[data-confirm]").on("click", function (e) {
        e.preventDefault();

        var target_form = $(this).closest("form");
        var _this = $(this);

        if (target_form.length) {
            Swal.fire({
                title: "Are you sure?",
                text:
                    _this.data("confirm") ||
                    "Just checking if you wanted to really do this.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, continue!",
            }).then(function (result) {
                if (result.value) {
                    $("<input>", {
                        type: "hidden",
                        name: _this.attr("name"),
                        value: _this.val(),
                    }).appendTo(target_form);

                    target_form.submit();
                }
            });
        }
    });

    $("#flatpickr_datepicker").each(function () {
        $(this).flatpickr();
    });

    /*------------------------------------------------
        Datetime picker (Flatpickr)
    ------------------------------------------------*/
    // Date and time
    if ($(".datetime-picker")[0]) {
        $(".datetime-picker").flatpickr({
            allowInput: true,
            enableTime: true,
            nextArrow: '<i class="fas fa-arrow-right" />',
            prevArrow: '<i class="fas fa-arrow-left" />',
            dateFormat: "Y-m-d H:i",
            time_24hr: true,
            altInput: true,
            altFormat: "F j, Y h:i K",
        });
    }

    // Date only
    if ($(".date-picker")[0]) {
        $(".date-picker").flatpickr({
            allowInput: true,
            enableTime: false,
            nextArrow: '<i class="fas fa-arrow-right" />',
            prevArrow: '<i class="fas fa-arrow-left" />',
        });
    }

    // Time only
    if ($(".time-picker")[0]) {
        $(".time-picker").flatpickr({
            allowInput: true,
            noCalendar: true,
            enableTime: true,
        });
    }

    /**
     * Entity Fetch
     */
    $('[data-entity-fetch]').on('click', function (e) {
        e.preventDefault();

        // abort if disabled
        if ($(this).hasClass('disabled')) {
            return;
        }

        var prefix = $(this).data('entity-fetch') || '';
        var url = $(this).data('entity-fetch-url');
        var _this = this;
        var entity_id_no = $('#' + prefix + 'id_no').val();

        if (!entity_id_no) {
            alert('please enter al the required details')
            // swal('', 'Please enter all the required details.', 'info');
            return;
        }

        $.ajax({
            url: url,
            dataType: 'json',
            delay: 250,
            type: 'GET',
            data: {
                filter: {
                    search: entity_id_no,
                },
                append: '',
                include: '',
                fields: 'id,name,nid_no,gender,email',
                per_page: 1
            },
            beforeSend: function () {
                toggleLoading($(_this), true);
            },
            complete: function () {
                toggleLoading($(_this), false);
            },
            success: function (results) {
                if (results.total) {
                    var entity = results.data[0];
                    console.log(entity)
                    var match_el = $('.entity-match');

                    match_el.find('.name').text(entity.name);
                    match_el.find('.nid_no').text(entity.nid_no);
                    match_el.find('.gender').text(entity.gender);
                    match_el.find('.email').text(entity.email);

                    match_el.find('[data-use-entity]')
                        .data('use-entity', entity.id)
                        .data('entity-name', entity.name);

                    $('.entity-no-match').hide();
                    $('.entity-form').hide();
                    match_el.slideDown();
                } else {
                    $('.entity-match').hide();
                    $('.entity-no-match').slideDown();
                    $('.entity-form').slideDown().trigger('change');
                    // bindSelectCustom($(this))
                }
            },
            error: function (xhr) {
                if (xhr.status == 422) {
                    showValidationErrorMsg(xhr);
                } else {
                    swal('Error!', 'An error occurred while fetching entity data.', 'error');
                }
            }
        });

    });

    $('[data-read-notification-id]').on('click', function (e) {
        e.preventDefault();
        let notificationId = $(this).data('read-notification-id');
        let index_url = $(this).data('url');
        let url = $(this).data('notification-url');

        $.ajax({
            // url: '{{ route('portal.notifications.index') }}/' +notificationId,
            url: index_url + '/' + notificationId,
            method: 'PATCH',
            success: function () {
                if (url) {
                    window.location.href = url;
                }
            }
        });
    });

});

$.fn.select2.defaults.set("theme", "bootstrap-5");
